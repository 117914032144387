.header{
    background: #1d1d1c;
}

.titleAPP{
    margin: 20px 0 0 40px ;
    font: 700 3em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    padding: 0.25em 0 0.325em;
    display: block;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);

    background: url(../../img/text-fill.png) repeat-y;
    -webkit-background-clip: text;
    background-clip: text;

    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
}

@media (max-width: 480px) {
    .titleAPP{
        margin: 20px 0 0 20px ;
        font: 700 2em/1 "Oswald", sans-serif;
    }
}