.date_selection{
    font-size: 24px;
    margin-left: 5px;
    margin-bottom: 0;
}
.address_info{
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.phone_info{
    font-size: 16px; 
    display: flex;
    align-items: center;
}

.card_day{
    margin:  5px ;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
}


.card_day:hover
{
    cursor: pointer;
    box-shadow: 0px 0px 15px #34495e;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.05);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.05);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.05);
    transition: all 200ms ease-in;
    transform: scale(1.05);
}

.card_day .card-title{
    font-size: 16px;
}

.custom-btn{
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}

.btn-prev {
    background-color: #34495e;
    background-image: linear-gradient(315deg, #2c2b31 0%, #34495e 74%);
    line-height: 42px;
    padding: 0;
    border: none;
    margin-bottom: 30px;
  }
  .btn-prev span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .btn-prev:before,
  .btn-prev:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: #34495e;
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
  }
  .btn-prev:before{
     height: 0%;
     width: 2px;
  }
  .btn-prev:after {
    width: 0%;
    height: 2px;
  }
  .btn-prev:hover:before {
    height: 100%;
  }
  .btn-prev:hover:after {
    width: 100%;
  }
  .btn-prev:hover{
    background: transparent;
  }
  .btn-prev span:hover{
    color: #34495e;
  }
  .btn-prev span:before,
  .btn-prev span:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #34495e;
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
  }
  .btn-prev span:before {
    width: 2px;
    height: 0%;
  }
  .btn-prev span:after {
    height: 2px;
    width: 0%;
  }
  .btn-prev span:hover:before {
    height: 100%;
  }
  .btn-prev span:hover:after {
    width: 100%;
  }
    
  .days-of-weak{
    font-size: 14px;
  }
  
  hr.linear {
    border: none;
    border-radius: 5px;
    height: 5px;
    background-image: -webkit-linear-gradient(315deg, #f1a809 25%, #a07c07 25%, #718d0b 50%, #b38218 50%, #a76e05 75%, #a87405 75%, #ec9614 100%);
    background-image: linear-gradient(135deg, #fff 25%, rgb(209, 162, 7) 25%, #c9710d 50%, #fdfdfd 50%, #4D4D4D 75%, #0000FF 75%, #0000FF 100%);
    background-size: 2rem 2rem;
  }

  .station_address{
    font-size: 18px;

  }

  .station_phone{
    font-size: 16px;

  }
  
  
@media (min-width: 570px) and (max-width: 991px) {
  .card_day{
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 569px) { 
  .cardContent{
    padding: 0 15px;
  }
  .date_selection{
    font-size: 20px;
  }
}

