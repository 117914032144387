.station_selection{
    font-size: 24px;
    margin-left: 5px;
}

.container-fluid{
    padding: 0 !important;
}

.text_info_system {
    background: #34495e; 
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}

/* .stations{
    padding: 0 300px;
} */


.zapis_zone{
    padding: 0 100px;
    flex: 1 1 auto;

}


@media (min-width: 768px) and (max-width: 991px) {
    .zapis_zone{
        padding: 0 35px;
    }
}

@media (max-width: 767px) {
    .text_info_system {
        font-size: 14px;
    }
    .zapis_zone{
        padding: 0 25px;
    }
    
}

@media (max-width: 570px) {
    .station_selection {
        font-size: 18px;
    }
    .zapis_zone{
        padding: 0 10px;
    }
}