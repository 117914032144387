.card_time{
    margin:  5px ;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
}


.card_time:hover
{
    cursor: pointer;
    box-shadow: 0px 0px 5px #354657;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.05);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.05);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.05);
    transition: all 200ms ease-in;
    transform: scale(1.05);
}

.succes{
    background-color: #ededed;
}
.form_success, .form_zapis{
    font-family: 'RobotoSlab-Regular', sans-serif;
    margin: auto;
    background-color: #fff;
    max-width: 540px;
    min-width: 320px;
    margin: 0;
    padding: 30px 25px;
    
    position: relative;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.form_zapis_body{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ededed;
}

@media (min-width: 570px) and (max-width: 991px) {
    .card_time{
      padding: 0;
    }
  }
  
@media (min-width: 320px) and (max-width: 569px) { 
    .form_success{
        font-size: 12px;
    }
}
  