.icon_address{
    background: url('../../../img/address.png') no-repeat;
}

.icon_phone{
    background: url('../../../img/phone.png') no-repeat;
}

.icon_categories{
    background: url('../../../img/car.png') no-repeat;
}


.address_block, .phone_block, .categories_block{
    display: flex; 
    flex-direction: row;
    margin: 5px 0;
}

.icon_address, .icon_phone, .icon_categories{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: contain;
    justify-content: center;

    margin: 5px 10px 0 10px;
}

.station_card{
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);   
}

.station_card:hover{
    cursor: pointer;
    box-shadow: 0px 0px 15px #34495e;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.05);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.05);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.05);
    transition: all 200ms ease-in;
    transform: scale(1.05);
}

.cat_info{
    font-size: 12px;
}


@media (min-width: 768px) and (max-width: 991px) {
    .card-header{
        padding: 10px;
        font-size: 14px;
    }
    .station_card .card-body{
        font-size: 13px;
        padding: 0;
    }
}

@media (max-width: 767px) {

    
}

@media (max-width: 570px) {


}